import React from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Bold = ({ children }) => <strong>{children}</strong>;
const Italic = ({ children }) => <i>{children}</i>;
const Text = ({ children }) => <p className="span-8">{children}</p>;
const H1 = ({ children }) => <h1 className="span-8">{children}</h1>;
const H2 = ({ children }) => <h2 className="span-8">{children}</h2>;
const H3 = ({ children }) => <h3 className="span-8 innerx4 rauto">{children}</h3>;
const Quote = ({ children }) => <blockquote className="span-8 innerx4 rauto">{children}</blockquote>;
const Embed = (props) => {
  const { fields } = props.data.target
  const iframe = fields?.embedCode["en-US"] || ""
	
  return (
    <div className="span-8 rauto innerx4">
      <div className="prel inlb w100" style={{ paddingTop: `${9 / 16 * 100}%` }}>
        <div className="pabs fs fs-iframe" dangerouslySetInnerHTML={{__html: iframe}} />
      </div>
    </div>
  )
}

const Image = (props) => {
  const { file, title } = props.data.target.fields

	const fileByLocale = file[props.locale || "en-US"] ? file[props.locale || "en-US"].url : file["en-US"].url

  return (
    <picture className="span-8 innerx4 rauto article-img">
      <img src={`${fileByLocale}?w=1800&q=50`} alt={title[props.locale || "en-US"]} />
    </picture>
  )
}

const options = ({ locale = "en-US" }) => {
	return {
		renderMark: {
			[MARKS.BOLD]: text => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: text => <Italic>{text}</Italic>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
			[BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
			[BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
			[BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
			[BLOCKS.QUOTE]: (node, children) => <Quote {...node}>{children}</Quote>,
			[BLOCKS.EMBEDDED_ENTRY]: (node, children) => <Embed {...node} locale={locale}>{children}</Embed>,
			[BLOCKS.EMBEDDED_ASSET]: (node, children) => node.data.target.fields && <Image {...node} locale={locale}>{children}</Image>,
		},
		renderText: text => {
			return text.split('\n').reduce((children, textSegment, index) => {
				return [...children, index > 0 && <br key={index} />, textSegment];
			}, []);
		}
	}
}

const RichText = ({json, locale}) => documentToReactComponents(json, options({ locale }))

export default RichText
